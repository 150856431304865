import './App.css';
import React,{useState, useEffect,} from "react";
import emailjs from '@emailjs/browser';
import Navbar from './Components/MiniComponent/Navbar';
import Intro from './Components/Intro/Intro';
import Sidebar from './Components/MiniComponent/Sidebar';
import About from './Components/About/About';
import Skill from './Components/Skill/Skill';
import Work from './Components/Work/Work';
import Contact from './Components/Contact/Contact';
import GithubCalender from './Components/GithubCalender/GithubCalender';

function App() {

const [ipAddress, setIpAddress] = useState(null);
const [lat, setLat]=useState(null);
const [long, setLong]=useState(null);
const [ref, setRef]=useState(null);

function getCurrentDateTime() {
  const date = new Date();

  // Extract day, month, and year
  let day = date.getDate();
  let month = date.getMonth() + 1; // Months are zero-based
  const year = date.getFullYear();

  // Format day and month to have two digits
  day = day < 10 ? '0' + day : day;
  month = month < 10 ? '0' + month : month;

  // Extract hours, minutes, and seconds
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  // Determine AM or PM suffix
  const ampm = hours >= 12 ? 'PM' : 'AM';

  // Convert hours to 12-hour format
  hours = hours % 12;
  hours = hours ? hours : 12; // The hour '0' should be '12'

  // Format minutes and seconds to have two digits
  const minutesFormatted = minutes < 10 ? '0' + minutes : minutes;
  const secondsFormatted = seconds < 10 ? '0' + seconds : seconds;

  // Construct date and time strings
  const formattedDate = `${day}-${month}-${year}`;
  const formattedTime = `${hours}:${minutesFormatted}:${secondsFormatted} ${ampm}`;

  return { formattedDate, formattedTime };
}

const SendToForm = async () => {

    const formData = new FormData();

    // Add data to the form data
    const { formattedDate, formattedTime } = getCurrentDateTime();
    
    // formData.append("Time, ")
    formData.append("Moment", formattedTime);
    formData.append("Day", formattedDate);
    formData.append("IP Address", ipAddress);
    formData.append("Latitude", lat);  
    formData.append("Longitude", long);
    formData.append("Referrer", ref || 'Direct Access');
    
    const payload={
      IP_Address:ipAddress,
      Latitude:lat,
      Longitude:long,
      Referrer:ref || 'Direct Access'
    }
  
    sendEmail(payload);
    console.log("Success")

    try {
       await fetch(process.env.REACT_APP_FORM_SCRIPT,{ method: "POST", body: formData});
    } catch (error) {
      console.log("Contact:aasifwali16@gmail.com about this error.")
    }      
};
const getUserLocation = async () => {
      // Check if Geolocation is supported by the browser
      if (!('geolocation' in navigator)) {
        console.error('Geolocation is not supported by this browser.');
        setLat("Location not supported by Browser")
        setLong("Location not supported by Browser")
        setRef(document.referrer);

        return;
      }

      try {
            navigator.geolocation.getCurrentPosition(
            // Success callback
            (position) => {
              const latitude = position.coords.latitude;
              const longitude = position.coords.longitude;
              setLat(latitude);
              setLong(longitude);
              setRef(document.referrer);
            },
            // Error callback
            (error) => {
              
                setLat(error.message);
                setLong(error.message);
                setRef(document.referrer );
              }
            );
             
      }       
      catch (error) {
        setLat(error.message);
        setLong(error.message);
        setRef(document.referrer);
        
        
      }
};
const fetchIPAddress = async() => {
    try {
      const response = await fetch("https://api.ipify.org?format=json");
      const data = await response.json();
      setIpAddress(data.ip);
      
    } catch (error) {
      setIpAddress(error);
     
      
    }
};
const sendEmail = (payload) => {


  
  emailjs.send( process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, payload, process.env.REACT_APP_PUBLIC_KEY)
    .then(
      () => {
       
      },
      (error) => {
       
      },
    );
};
useEffect(() => {
  fetchIPAddress();
  getUserLocation();
  
}, []);
useEffect(()=>{
  if(ipAddress && lat && long){
    SendToForm();
  }
 
}, [ipAddress, lat, long]);



return (
    <div className="App">
        <Navbar />
        <Intro/>
        <Sidebar/>
        <About  />
        <Skill/>
        <Work/>
        <GithubCalender/>
        <Contact/>
    </div>
  );
}

export default App;
