
import kfc from './media/kfc.mp4'
import CarverSkateboards from './media/CarverSkateBoards.mp4'
import HillStationTravels from './media/HillStationTravel.mp4'
import KoshurPrinters from "./media/Koshur_Printers.mp4"
import GWT from "./media/GWT.mp4"
import Timeless from "./media/TimelessBeautyTheme.mp4"
import Gossip from "./media/Let's Gossip.mp4"
import Silk from "./media/Silk Road Cartistry.mp4"

export const details={
    firstname:"Mohammad",
    lastname:"Asif",
    
    navList:[{
        val1:"Home",
        val2:"nav-link home"
      },{
        val1:"About",
        val2:"nav-link about"
      },{
        val1:"Skills",
        val2:"nav-link skills"
      },{
        val1:"Projects",
        val2:"nav-link projects"
      },{
        val1:"Contact",
        val2:"nav-link contact"
      }],
    scrollPosition:[0,545,1070,1730,3400],
    className:["introDiv","about section","skillContainer","workDiv","contactDiv"],
    linkedInLink:"https://www.linkedin.com/in/awali016/",
    githubLink:"https://github.com/Asif-Wali",
    phone: 6005070703,
    mailLink:'aasifwali16@gmail.com',
    techStack:[
            {
                name:"HTML",
                img:"https://img.icons8.com/color/512/html-5--v1.png"
            },
            {
                name:"CSS",
                img:"https://img.icons8.com/fluency/512/css3.png"
            },
            {
                name:"JavaScript",
                img:"https://img.icons8.com/color/512/javascript.png"
            },
            {
                name:"TypeScript",
                img:"https://img.icons8.com/?size=100&id=nCj4PvnCO0tZ&format=png&color=000000"
            },
            {
                name:"Java",
                img:"https://img.icons8.com/?size=100&id=Pd2x9GWu9ovX&format=png&color=000000"
            },
            {
                name: "Python",
                img:"https://img.icons8.com/?size=100&id=l75OEUJkPAk4&format=png&color=000000"
            },
            {
                name:"React",

                img:"https://img.icons8.com/?size=100&id=asWSSTBrDlTW&format=png&color=000000"
            },
            // {
            //     name:"Angular",
            //     img: "https://img.icons8.com/?size=100&id=71257&format=png&color=000000"
            // },
            {
                name: "Next.JS",
                img: "https://img.icons8.com/?size=100&id=gwR0hbBi5JeZ&format=png&color=FFFFFF"
            },
            {
                name:"Redux",
                img:"https://img.icons8.com/?size=512&id=jD-fJzVguBmw&format=png"
            },
            {
                name:"Node JS",
                img:"https://img.icons8.com/fluency/512/node-js.png"
            },
            {
                name:"Express JS",
                img:"https://img.icons8.com/?size=100&id=kg46nzoJrmTR&format=png&color=FFFFFF"
            },
            {
                name:"Material UI",
                img:"https://imgs.search.brave.com/2Ze_UTQD-t32dowiUruor57G5SzyLr3kGUXjmfXTLaE/rs:fit:500:0:0/g:ce/aHR0cHM6Ly9hc3Nl/dC5icmFuZGZldGNo/LmlvL2lkU1AxVWtf/QVUvaWQxNm9OeVV6/RC5wbmc_dXBkYXRl/ZD0xNzA4NzE3OTQz/NTI1",

            },
            {
                name: "Chakra-UI",
                img:"https://img.icons8.com/?size=100&id=r9QJ0VFFrn7T&format=png&color=000000"
            },
            {
                name:"Tailwind CSS",
                img: "https://img.icons8.com/?size=100&id=4PiNHtUJVbLs&format=png&color=000000"
            },
            {
                name:"Shopify Liquid",
                img: "https://img.icons8.com/?size=100&id=uSHYbs6PJfMT&format=png&color=000000"
            },
            {
                name:"Mongo DB",
                img:"https://img.icons8.com/color/512/mongodb.png"
            },
            {
                name:"Mongoose ODM",
                img: "https://img.icons8.com/?size=100&id=gKfcEStXI1Hm&format=png&color=000000"
            },
            // {
            //     name:"MY SQL",
            //     img:"https://img.icons8.com/?size=100&id=UFXRpPFebwa2&format=png&color=000000"
            // },
    ],
    projects:[
        {
            name:"Koshur Printers",
            github:"https://github.com/Asif-Wali/KoshurPrinters",
            live:"https://koshurprinters.vercel.app/",
            type:"Freelance-Ongoing",
            tech:["React", "Tailwind CSS", "Node JS", "Express JS","Mongoose ODM","Mongo DB"],
            img:KoshurPrinters,
            description:"I am currently developing an e-commerce platform for Koshur Printers, a company that specializes in screen printing, textile printing, and customized printing on a variety of items including T-shirts, mugs, caps, and other accessories. This project demonstrates my skills in creating a robust online store that caters to businesses, events, and individuals."
        },
        {
            name:"Let's Gossip",
            github:"https://github.com/Asif-Wali/MERN-Chat_App",
            live:"https://main--letsgossiptogether.netlify.app",
            type:"Solo",
            tech:["CSS","React", "Node JS", "Mongoose ODM","Express JS", "Mongo DB"],
            img:Gossip,
            description:"Inspired by Kishen Seth's YouTube tutorials, I developed a real-time chat application using WebSockets and MongoDB for message storage. The backend, written in Node.js, is deployed on Render, while the frontend is hosted on Netlify. This project demonstrates my proficiency in creating interactive web applications with real-time capabilities."
        }, 
        {
            name:"Timeless Beauty",
            github:"",
            live:"https://timelessthemestore.myshopify.com",
            type:"Collaborative",
            tech:["HTML","CSS", "Tailwind CSS", "JavaScript", "Shopify Liquid"],
            img:Timeless,
            description:`To view the project, click the link and enter the password: "Sunilhooda@3696". It's crafted with Shopify's Liquid templating language for storefront themes. I am responsible for the collection page which includes product, product detail, header, footer pages and components. Besides I have also added sorting, filtering , &pagination.`
        },
        {
            name:"Silk Road Cartistry",
            github:"https://github.com/Asif-Wali/SilkRoadCartistry",
            live:"https://Silkroadcartistry.vercel.app/",
            type:"Freelance-Ongoing",
            tech:["React", "Tailwind CSS", "Material UI"],
            img:Silk,
            description:"I'm currently crafting an e-commerce platform for a client showcasing traditional Kashmiri products like saffron, scarfs, pashmina etc. This ongoing project illustrates my proficiency in developing robust online stores tailored for businesses, individuals, etc, underscoring my expertise in creating versatile and user-friendly e-commerce solutions"
        },
        {
            name:"Global Web Technology",
            github:"https://github.com/Asif-Wali/GWT-WebSite",
            live:"https://globalwebtechnology.in",
            type:"Collaborative",
            tech:["HTML","CSS", "Tailwind CSS", "JavaScript"],
            img:GWT,
            description:"The project, initially Angular-based, transitioned to HTML, CSS, and JavaScript with Tailwind CSS. Got to explore Angular framework . This live project is integrated with Email.js and also stores data on Google Sheet "
        },
        
       
        {
            name:"Hill Station",
            github:"https://github.com/Rohit27698/hilling",
            live:"https://hilling-3e85n1yls-rohit27698.vercel.app/",
            type:"Collaborative",
            tech:["React","Redux","Chakra-UI"],
            img:HillStationTravels,
            description:"Hill Station is a website which actually helps many different people to choose and book their favorite destinations for a memorable vacation. Here the tourist gets a variety of different destinations to choose from. It includes both Domestic as well as International tourist destinations."
        },
        {
            name:"Skateboards",
            github:"https://github.com/slowandrarecooked/teamCarver",
            live:"https://glittering-banoffee-6ec2a1.netlify.app/",
            type:"Collaborative",
            tech:["HTML","CSS","JavaScript"],
            img:CarverSkateboards,
            description:"Carver Skateboards is a renowned brand that specializes in producing high-quality skateboards designed for a unique and dynamic riding experience .Carver Skateboards is at the forefront of a skateboarding revolution, redefining the way riders experience the streets and sidewalks."
        },
        {
            name:"KFC",
            github:"https://github.com/Shubham10320/KFC",
            live:"https://kentuckyfriedchicken.netlify.app/",
            type:"Collaborative",
            tech:["CSS","HTML", "JavaScript"],
            img:kfc,
            description:"KFC, or Kentucky Fried Chicken, is a well-known international fast-food restaurant chain specializing in fried chicken dishes. KFC has a global presence, with thousands of locations in numerous countries, making it one of the world's largest and most recognizable fast-food chains."
        },
       
    ]

}






