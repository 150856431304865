
import { Box, ListItem, Typography, Zoom } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { details } from '../../details'
import "./Skill.css"

const Skill = () => {
  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [scrolled, setScrolled] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(document.documentElement.scrollTop);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (scrolled >= 758) {
      const timer1 = setTimeout(() => {
        setChecked2(true);
      }, 600);
      const timer2 = setTimeout(() => {
        setChecked1(true);
      }, 800);

      return () => {
        clearTimeout(timer1);
        clearTimeout(timer2);
      };
    }
  }, [scrolled]);



  return (
    <Box id="skills" sx={{ width: "90%", margin: "15% auto", padding: "0% 10px", }}>
      <Box sx={{ borderTop: "2px solid rgb(35,53,84)",  position: "" }} >
        <Zoom in={checked2}>
          <Typography className='skillHeading' variant='h4' sx={{ color: "rgb(136,146,176)", width:"fit-content", backgroundColor: "rgb(11,24,47)",   padding: "0% 5% 0% 5%",   fontFamily: "Poppins", marginTop:"-22px", marginLeft:"auto",marginRight:"auto" }} >Skills</Typography>
        </Zoom>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", marginTop: "5%", flexWrap: "wrap", rowGap: "18px", columnGap:"28px" , }}>
        {
          details.techStack.map((ele, index) => {
            return (
              <ListItem className='skillContainer' key={index} style={{ display: "block", width: "20%" , border:"3px solid rgb(58,146,136)", borderRadius:"8px"}}>
                <Zoom in={checked1}>
                  <Box className="skills-card" >
                    <img className="skills-card-img" width={"45%"} style={{ margin: "auto", display: "block" , borderRadius:"5px"}} src={ele.img} alt=""/>
                    <Typography className="skills-card-name" sx={{ textAlign: "center", fontWeight:'600',color: "rgb(136,146,176)", fontFamily: "Poppins" }}>{ele.name}</Typography>
                  </Box>
                </Zoom>
              </ListItem>
            )
          })
        }
      </Box>
    </Box>
  )
}

export default Skill