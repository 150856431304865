import { Box, Button, Typography, Zoom } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { details } from '../../details';

import "./Intro.css"
import Typewriter from 'typewriter-effect/dist/core'
import Scroll from "react-scroll-to-element";
const Intro = () => {
    const [checked,setChecked]= useState(false);
   

    const introLoaded=()=>{
        setTimeout(()=>{
            setChecked(true);

        },800)
    }
    useEffect(()=>{
        introLoaded();
    },[])
    var typewriter = new Typewriter(document.getElementById("subHeading"), {
        loop: true,
        delay: 75,
      });
    typewriter
    .pauseFor(500)
    .typeString('I am a <strong>Full Stack Developer<strong>')
    .pauseFor(1000)
    .deleteChars(27)
    .typeString('I build things for <strong>Front-End</strong>')
    .pauseFor(300)
    .deleteChars(13)
    .typeString('and for <strong>Back-End</strong>')
    .pauseFor(1000)
    .start();
  return (
    <Box id="home" className='introDiv'  sx={{width:"80%",margin:"auto",marginTop:"5%"}}>
        <Box className='headingContainer' sx={{cursor:"pointer",width:"fit-content"}}>
            <Zoom  in={checked}>
                {/* <Typography className="heading1" sx={{color:"rgb(191,201,232)",fontFamily:"Poppins",fontSize:"6rem",fontWeight:'600'}} variant='h1' >{details.firstname+" "+details.lastname}</Typography> */}
                
                <Typography id="user-detail-name" className="heading1" sx={{color:"rgb(204,214,246)",fontFamily:"Poppins",fontSize:"4rem",fontWeight:'600'}} variant='h1' >{details.firstname+" "+details.lastname}</Typography>
            </Zoom>    
        </Box>
        <Zoom  in={checked}>
        
            <Typography id="subHeading" className='heading1' sx={{color:"rgb(126,136,165)",fontFamily:"Poppins",fontSize:"4rem"}} variant='h1'></Typography>
        </Zoom>
        <Zoom  in={checked}>
            <Typography id="user-detail-intro" className='intro' sx={{color:"rgb(126,136,165)",fontFamily:"Poppins",width:"70%",marginTop:"2%",fontSize:"1.2rem"}} variant='h5'>I'm a skilled Full Stack Developer who loves building user-friendly websites and applications. With experience in both front-end and back-end technologies, I create solutions that blend great design with strong functionality. I'm adaptable, keep up with tech trends, and enjoy collaborating with teams to turn ideas into digital reality.
            <br/><br/>
             Let's innovate together!</Typography>
        </Zoom>
        <Scroll offset={-150} type="class" element={details.className[3]}>
            <Zoom in={checked}>
                
                <Button 
                className='goToButton'
                sx={{
                    border: "1px solid rgb(89,228,197)",
                    color: "rgb(89,228,197)",
                    fontSize: "1rem",
                    marginTop:"4%",
                    padding:"1%",
                    fontFamily:"Poppins",
                    marginBottom:'5rem' 
                }}>Check Out My Work</Button>
            </Zoom>
        </Scroll>
    </Box>
  )
}

export default Intro