import { Box, Button, Typography} from '@mui/material'
import React from 'react'
import { details } from "../../details"
import "./Contact.css"

const Contact = () => {
    
    return (
        <Box id="contact" className="contactDiv"sx={{ width: "80%", margin: "10% auto", padding: "0% 10px" }}>
            <Typography className='contactheading' variant='h2' sx={{ textAlign: "center", fontWeight: "600", color: "#64ffda", fontFamily: "Poppins" }}>Get In Touch</Typography>
            <Typography className='contactSubHeading' variant='h6' sx={{ textAlign: "center", width: "70%", margin: "auto", color: "#c8d2f1", fontFamily: "Poppins" }}>I'm actively exploring new opportunities, and my inbox is readily accessible. Whether you have a question or simply want to say hello, I'll do my utmost to respond promptly!</Typography>
            <a href="https://wa.me/916005070703" target='_blank' rel="noreferrer" className=' no-underline' style={{ textDecoration: "none" }}>
            <Button className='contactButton no-underline' variant="outlined" sx={{ border: "1px solid #64ffda", color: "#64ffda", fontSize: "0.9rem", margin: "auto", padding: "1% 3%", display: "block", marginTop: "2%", fontFamily: "Poppins",  textDecoration: "none" }}>Say Hello</Button> </a>
            <Box className='contact-logo' sx={{ marginTop: "2%", display: "flex", justifyContent: "center", alignContent: "start", alignItems: "center", gap: "15px", flexDirection: "column" }}>
                <a className='socialLogo' id="contact-github" target="_blank" rel="noopener noreferrer" href={details.githubLink} style={{ textDecoration: "none", color: "#c8d2f1", fontSize: "1.3rem" }}><i className="fa-brands fa-github"></i> Asif-Wali</a>
                <a className='socialLogo' id="contact-linkedin" target="_blank" rel="noopener noreferrer" href={details.linkedInLink} style={{ textDecoration: "none", color: "#c8d2f1", fontSize: "1.3rem" }}><i className="fa-brands fa-linkedin"></i>   Mohammad Asif</a>
                <a className='socialLogo' id="contact-phone" target="_blank" rel="noopener noreferrer" href={`tel:+91${details.phone}`} style={{ textDecoration: "none", color: "#c8d2f1", fontSize: "1.3rem" }}><i className="fa-solid fa-phone"></i>   +91 6005070703</a> 
                <a className='socialLogo' id="contact-email" target="_blank" rel="noopener noreferrer" href={`mailto:${details.mailLink}`} style={{ textDecoration: "none", color: "#c8d2f1", fontSize: "1.3rem" }}><i className="fa-regular fa-envelope"></i>   aasifwali16@gmail.com</a>
            </Box>
        </Box>
    )
}

export default Contact