import { Box, Button, Grid, Typography,} from '@mui/material'
import React from 'react';
import "./About.css"

import LinearDeterminate from '../MiniComponent/ProgressComp';
import myPhoto from '../../media/myPhoto.jpg'
import myResume from '../../media/Mohammad-Asif-Resume.pdf';

const About = () => {
   
    
   

    const openResume = () => {
        window.open(
            // "https://drive.google.com/file/d/1Np1R0XMHGLjukY6VQIufpIpI9cgmC3D2/view?usp=sharing",
            "https://drive.google.com/file/d/1ijnkCDVg1evzvJNoPeokRLp6g-b5BiE1/view?usp=sharing",
            "_blank"
        );
    };

    return (
 
        <Grid container id="about" className='about section' sx={{ width: "80%", margin: "10% auto", padding: "0% 10px" }} spacing={2}>
            <Grid item xs={12} sm={6} md={6} sx={{ margin: "auto" }}>
                <Box sx={{ borderTop: "2px solid rgb(35, 53, 84)", position: "relative" }}>
                    <Typography className='aboutHeading' variant='h4' sx={{ color: "rgb(136,146,176)", backgroundColor: "rgb(11, 24, 47)", position: "absolute", top: "-25px", padding: "0% 5% 0% 0%", fontFamily: "Poppins" }}>About Me</Typography>
                    <Typography id="user-detail-intro" className='aboutText' sx={{ marginTop: "8%", color: "rgb(136,146,176)", fontFamily: "Poppins" }}>
                            Although my academic journey began with a
                            <span style={{ color: "rgb(74,188,167)",fontWeight:'600' }}> Bachelor's degree in Science</span>,
                            my path swiftly diverged when I discovered an unexpected passion for 
                              <span style={{ color: "rgb(74,188,167)",fontWeight:'600' }}> Coding</span>. With unwavering determination, I transformed this newfound interest into a thriving career pursuit.
                            <br/><br/>
                            I embarked on a transformative journey by enrolling in the prestigious institution called <span style={{ color: "rgb(74,188,167)",fontWeight:'600' }}> Masai School</span>, where I immersed myself in the world of <span style={{ color: "rgb(74,188,167)",fontWeight:'600' }}> Web Development, Java Programming</span> and mastering essential<span style={{ color: "rgb(74,188,167)",fontWeight:'600' }}> Data Structures and Algorithms (DSA)</span>. This experience allowed me to channel my passion into a tangible skill set, shaping me into a proficient and enthusiastic Developer.
                            <br/><br/>
                            After completing my program at<span style={{ color: "rgb(74,188,167)",fontWeight:'600' }}> Masai School</span>, I am currently furthering my expertise by studying <span style={{ color: "rgb(74,188,167)",fontWeight:'600' }}> DevOps and Cloud Computing</span>, at <span style={{ color: "rgb(74,188,167)",fontWeight:'600' }}> PW Skills. </span>
                            My unique background in <span style={{ color: "rgb(74,188,167)",fontWeight:'600' }}> science, combined with my comprehensive coding knowledge,</span> has equipped me with a diverse skill set and a well-rounded perspective. I am now eager to contribute my expertise to innovative projects and teams in the field of web development.
                            <br/><br/>
                            My journey from Science to Coding is a testament to my <span style={{ color: "rgb(74,188,167)",fontWeight:'600' }}> resilience, adaptability, and relentless pursuit of personal and professional growth.</span> I am excited to leverage my skills and passion to make a meaningful impact and create exceptional digital experiences.
                    </Typography>
                    <Box id="resumeBtn" onClick={openResume} marginTop='1.5rem' sx={{ display: "flex", justifyContent: "center" }}>
                        <Button variant="outlined" href={myResume} download="Mohammad-Asif-Resume" id="resume-button-2" sx={{ border: "2px solid rgb(58,146,136)", color: "rgb(58,146,136)", fontSize: "16px", fontFamily: "Poppins", margin: "auto" }}>Resume</Button>
                        <LinearDeterminate color={"transparent"} />
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12} sm={6} sx={{ margin: "auto" }}>
                <Box className='imageContainer' sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <img className='home-img' style={{ display: "block", margin: "auto", borderRadius: "8px",padding:'7px', borderBottom: '2px solid rgb(126,136,165)', borderRight:'2px solid rgb(126,136,165)' }} width={"50%"} src={myPhoto} alt=''/>
                </Box>
            </Grid>
        </Grid>
    )
}

export default About