import { Box, Typography } from "@mui/material";
import React from "react";
import GitHubCalendar from "react-github-calendar";

export default function GithubCalender() {
  return (
    <>
      <Typography className='contactheading' sx={{ textAlign: "center", fontWeight: "600", color: "rgb(136,146,176)", marginBottom: "2%", fontFamily: "Poppins" }} variant='h3' >My Contributions</Typography>
      <Box sx={{ textAlign: "center", width: "fit-content", margin: "auto auto 0% auto", color: "rgb(136,146,176)", borderRadius: "8px", padding: "3% 1%", border: "2px solid rgb(100,255,218)" }}>
        <GitHubCalendar
          className="react-activity-calendar"
          color="green"
          username="Asif-Wali" />
          
      </Box>
      <Box sx={{ textAlign: "left", width: "fit-content", margin: "auto", padding: "3% 1%" }}>
        <Box sx={{ width: "100%", maxWidth: "400px", margin: "0 auto" }}>
          <img id="github-top-langs" width='100%' src="https://github-readme-stats.vercel.app/api/top-langs?username=Asif-Wali&theme=transparent&hide_border=true&show_icons=true&locale=en&layout=compact&title_color=#64ffda&text_color=#64ffda" alt=""/>
        </Box>
        <Box sx={{ width: "100%", maxWidth: "400px", margin: "2rem auto" }}>
          <img id="github-stats-card" width='100%' src="https://github-readme-stats.vercel.app/api?username=Asif-Wali&theme=transparent&hide_border=true&show_icons=true&locale=en&title_color=#64ffda&text_color=#64ffda" alt="" />
        </Box>
        <Box sx={{ width: "100%", maxWidth: "400px", margin: "2rem auto" }}>
          <img id="github-streak-stats" width='100%' src='https://github-readme-streak-stats.herokuapp.com/?user=Asif-Wali&theme=transparent&hide_border=true&title_color=#64ffda&text_color=#64ffda&sideNums=00FF41&sideLabels=#64ffda&ring=00FF41&fire=#64ffda&currStreakLabel=00FF41&currStreakNum=00FF41' alt="" />
        </Box>
      </Box>

    </>
  );
}