import { Box, Button, Drawer, List, ListItem,  ListItemText, Slide, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import LinearDeterminate from "./ProgressComp";
import logo from "../../media/logo.jpg";
import "./Navbar.css";
import { details } from "../../details";
import Scroll from "react-scroll-to-element";
 import myResume from '../../media/Mohammad-Asif-Resume.pdf';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import { Link } from 'react-scroll';



const Navbar = ({ scollHandle }) => {
  const [checked, setChecked] = useState(false);
  const [hoverEffect, SetHoverEffect] = useState(0);
  const [scrolled, setScrolled] = useState(0);
  const [open, setOpen] = useState(false);
 
  const handleDrawerToggle = () => {
    setOpen(!open);

  }

  const introLoaded = () => {
    setTimeout(() => {
      setChecked(true);

    }, 600)
  }
  useEffect(() => {
    introLoaded();
  }, [])

  const openResume = () => {
    window.open(
      "https://drive.google.com/file/d/17LwSWNtAbQGKN1lxceVsS_5UJVb8msyh/view?usp=sharing",
      "_blank"
    );
  };

  window.addEventListener("scroll", () => {
    setScrolled(document.documentElement.scrollHeight);
  });

  const topwindows = () => {
    window.scrollTo({
      top: 0,
    });
  }

  return (
    //navbarboxshadow add blur effect when the scroll size is more than 100
    <Box
      id="nav-menu"
      className={scrolled >= 100 ? "navbarboxShadow" : ""}
      sx={{
        display: "flex",
        justifiyContent: "space-between",
        width: "100%",
        padding: "5px 1%",
        position: "sticky",
        top: "0%",
        zIndex: "10",
        // border: "2px dotted yellow"
      }}
    >
      {/* left sided nav box which contains logo*/}
      <Box
        sx={{
          width: "100%",
          padding: "0% 25px",
          display: "flex",
          alignItems: "center",
          gap: "5px",
          color: "white",
          // border: "2px dotted white"
        }}
      >
      {/*logo box*/ }
      <Box
        component="img"
        sx={{
          height: 45,
          width: 60,
          maxHeight: { xs: 233, md: 167 },
          maxWidth: { xs: 350, md: 250 },
          borderRadius: "2%",
          objectFit: "cover"
          // border: "2px dotted skyblue"
        }}
        alt="the Logo"
        src={logo}
        onClick={()=>{topwindows()}}
      />

      </Box>
     
      <Box
        className="navbar-part-2"
        p='1rem'
        sx={{
          display: "flex",
          width: "80%",
          color: "white",
          justifyContent: "space-between",
          alignItems: "center",
          // border: "2px dotted yellowgreen"
        }}
      >
        {
          details.navList.map((ele, index) => {
            return (
              <Scroll key={index} offset={-150} type="class" element={details.className[index]}>
                <Slide  direction="down" in={checked}>

                  <Box
                    className="navbarList"
                    sx={{ cursor: "pointer" }}
                    onMouseOut={() => {
                      SetHoverEffect(0);
                    }}
                    onMouseOver={() => SetHoverEffect(index + 1)}
                  >
                    
                    <Typography className={ele?.val2} variant="h5" sx={{ fontSize: "1.1rem", fontFamily: "Poppins", color: 'rgb(89,228,197)'}}>{ele.val1}</Typography>
                    {hoverEffect === index + 1 ? (
                      <LinearDeterminate color={"white"} />
                    ) : (
                      <Box sx={{ height: "4px" }}></Box>
                    )}
                  </Box>
                </Slide>
              </Scroll>
            )
          })
        }
        {/**/}
        <Box className='nav-link resume' onClick={openResume}>
          <a href={myResume} download='Mohammad-Asif-Resume'>
            <Button variant="outlined"
              download={myResume}
              id="resume-button-1"
              sx={{
                border: "1px solid rgb(89,228,197)",
                color: "rgb(89,228,197)",
                fontSize: "0.8rem",
                fontFamily: "Poppins",

              }}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 mr-2">
              <path stroke-linecap="round" stroke-linejoin="round" d="m9 13.5 3 3m0 0 3-3m-3 3v-6m1.06-4.19-2.12-2.12a1.5 1.5 0 0 0-1.061-.44H4.5A2.25 2.25 0 0 0 2.25 6v12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9a2.25 2.25 0 0 0-2.25-2.25h-5.379a1.5 1.5 0 0 1-1.06-.44Z" />
            </svg>
            Resume</Button>
          </a>

          <LinearDeterminate color={"transparent"} />
        </Box>

        <Box className="ham " sx={{ display: "none", color: "rgb(58,146,136)"}} >
          <MenuOutlinedIcon onClick={handleDrawerToggle}  />
          <Drawer
            sx={{
              width: 300, // Set the desired width
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: 300, // Set the desired width
                backgroundColor: 'bl', // Set the desired background color
                color: 'rgb(58,146,136)',
                // border: "3px solid brown",
              },
            }}
            variant="temporary"
            anchor="right"
            open={open}
            onClose={handleDrawerToggle}
          >
            <List sx={{ marginTop: '50px' }}>
              {details.navList.map((ele, index) => (
                <ListItem button key={index}>
                  <Link
                    activeClass="active"
                    to={details.className[index]}
                    spy={true}
                    smooth={true}
                    offset={-150}
                    duration={500}
                    onClick={handleDrawerToggle}
                    // className="border-2 border-[green]"
                  >
                    <ListItemText primary={ele.val1} sx={{ textAlign: 'center' }} />
                  </Link>
                </ListItem>
              ))}
            </List>
          </Drawer>
        </Box>
      </Box>
    </Box>
  );
};

export default Navbar;
